<template>
  <v-container fluid :class="$vuetify.breakpoint.mdAndUp ? '' : 'pt-10 pb-10'">
    <v-row style="max-width: 1200px" class="mx-auto">
      <v-col cols="12" align-self="center">
        <v-card class="transparent text-center" style="" flat>
          <v-card-title
            :class="$vuetify.breakpoint.smAndUp ? 'display-1' : 'title pb-0'"
          >
            <span class="mx-auto text_title">더 많은 작품이 궁금하신가요?</span>
          </v-card-title>
          <v-card-text
            :class="$vuetify.breakpoint.smAndUp ? 'title' : 'caption'"
            class="d-flex mt-2 mb-10"
          >
            <span
              class="mx-auto grey--text text--darken-2"
              :class="
                $vuetify.breakpoint.smAndUp
                  ? 'text_subtitle'
                  : 'subtitle-1 pb-0'
              "
            >
              작품을 선택하시면 자세한 내용을 볼 수 있어요
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-row v-if="fetchLoading">
        <v-col v-for="index in 4" :key="index" cols="6" md="3">
          <v-skeleton-loader class="rounded-lg" type="card" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col v-for="item in items" :key="item.id" cols="6" md="3">
          <div
            class="d-flex flex-column pa-3"
            style="
              width: 100%;
              overflow: hidden;
              gap: 8px;
              border-radius: 8px;
              background: var(--v-background_normal-base);
            "
          >
            <div
              class="d-flex justify-space-between align-center align-items-center"
            >
              <div @click="open('https://instagram.com/madeall3d_portfolio')">
                @madeall3d_portfolio
              </div>
              <div style="height: 18px" @click="open(item.permalink)">
                <feather type="external-link" size="18" />
              </div>
            </div>
            <div class="image-wrapper">
              <img
                :src="item.mediaUrl"
                alt="Instagram Post"
                class="responsive-image"
              />
            </div>
            <div
              class="body-1"
              style="white-space: pre-line"
              :title="item.caption"
            >
              {{ truncatedCaption(item.caption) }}
            </div>
            <div class="body-2">
              {{ new Date(item.timestamp) | moment("YYYY-MM-DD") }}
            </div>
          </div>
        </v-col>
      </v-row>

      <v-col cols="12" class="d-flex">
        <v-btn
          color="primary rounded-lg body-2 font-weight-bold"
          :large="$vuetify.breakpoint.mdAndUp"
          depressed
          @click="open('https://instagram.com/madeall3d_portfolio')"
          class="mx-auto mt-8"
          small
        >
          <feather size="18" type="plus" />
          작품 더보기
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      fetchLoading: false,
      options: {
        sortBy: ["createdAt"],
        sortDesc: [true],
      },
      items: [],
    };
  },
  mounted() {
    this.getPreview();
  },
  methods: {
    getPreview() {
      this.fetchLoading = true;
      this.$axios
        .get("instagram/")
        .then(({ data }) => {
          this.items = data.slice(0, 12);
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        })
        .finally(() => {
          this.fetchLoading = false;
        });
    },
    open(url) {
      window.open(url);
    },
    truncatedCaption(caption) {
      const lines = caption.split("\n");
      const filteredLines = lines.filter(
        (line) => line.trim() !== "." && line.trim() !== ""
      );
      const maxLines = 5;
      return filteredLines.slice(0, maxLines).join("\n");
    },
  },
};
</script>

<style scoped>
.text_title {
  color: #222;
  font-weight: 700;
  line-height: 130%; /* 40.8px */
}
.text_subtitle {
  color: #222;
  font-weight: 400;
  line-height: 130%;
}
.image-wrapper {
  width: 100%;
  padding-top: 75%; /* 4:3 비율 (height를 부모의 75%로 설정) */
  position: relative;
  overflow: hidden;
}

.responsive-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 비율 유지하면서 잘라냄 */
  object-position: center; /* 이미지를 중앙에서 잘라냄 */
}
</style>
